<template>
  <div class="main-report">
    <h2 class="mb-2">
      <span><i class="far fa-list-alt"></i></span> {{ $t("approved.approved") }}
    </h2>
    <v-sheet class="p-4">
      <v-row dense>

        <v-col cols="12" md="2" lg="2">
          <label class="label-input">{{ $t("approved.startDate") }}</label>
          <v-menu v-model="startDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="start_date" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="start_date" @input="startDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <label class="label-input">{{ $t("approved.endDate") }}</label>
          <v-menu v-model="endDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="end_date" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="end_date" @input="endDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <label class="label-input">{{ $t("approved.status") }}</label>
          <v-select outlined dense :items="todoStatus" item-value="name" item-text="name" v-model="status">
          </v-select>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-btn class="btn-action mt-7" @click="fetchEApproval">
            {{ $t("approved.search") }}
          </v-btn>

          <v-btn class="btn-action confirm mt-7" @click="exportExcel">
            Export Excel
            <!-- {{ $t("approved.export") }} -->
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{
            $t("ReportScanInOut.textsearchByname")
          }}</label>
          <v-text-field outlined dense v-model="filter" prepend-inner-icon="mdi-magnify"
            @keypress.enter="searchFilterItem">
          </v-text-field>
        </v-col>

        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("Employee.branch") }}</label>
          <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch" hide-selected
            v-model="branch_id" @change="handleBranch($event)">
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("Employee.dept") }}</label>
          <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment" hide-selected
            v-model="department_id" @change="handleDepartment($event)">
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="mt-4 pa-4" width="100%" height="auto" color="white">
      <table class="table table-view">
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("approved.date") }}
            </th>
            <th class="text-left">
              {{ $t("approved.approvedNumber") }}
            </th>
            <th class="text-left">
              {{ $t("approved.approvedFile") }}
            </th>
            <th>
              {{ $t("approved.title") }}
            </th>
            <th>
              {{ $t("approved.description") }}
            </th>
            <th>
              {{ $t("approved.applicant") }}
            </th>
            <th>
              {{ $t("approved.approvedBy") }}
            </th>
            <th>
              {{ $t("approved.referNumber") }}
            </th>
            <!-- <th>
              {{ $t("approved.dateApprove") }}
            </th> -->
            <th>{{ $t("approved.status") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in approved" :key="idx">
            <td width="130">
              {{ moment(item.created_time).format("YYYY-MM-DD") }}
              {{ moment(item.created_time).format("HH:mm:ss") }}
            </td>
            <td class="text-start">{{ item.approval_number }}</td>
            <td>
              <v-icon left @click="openFile(item.file_name)">
                mdi-file-eye
              </v-icon>
            </td>
            <td>{{ item.title }}</td>
            <td>{{ item.description }}</td>

            <td>{{ item.employee.name }} {{ item.employee.surname }}</td>
            <td>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in item.approved_bys" :key="i">
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="`${item.name} ${item.surname}`"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </td>
            <td>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in item.approved_bys" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.refer_number"></v-list-item-title>
                      <span style="font-size: 80%;" v-if="item.refer_number != null || item.status == 'rejected'
                        ">
                        {{ moment(item.approve_time).format("YYYY-MM-DD") }}
                        {{ moment(item.approve_time).format("HH:mm:ss") }}
                        <br />
                        <span v-if="item.remark != null">Note : </span>
                        <span style="color:red" v-if="item.status == 'rejected'">{{ item.remark }}</span>
                        <span style="color:rgb(23, 75, 230)" v-if="item.status == 'approved'">{{ item.remark }}</span>
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </td>
            <td>
              <v-list dense>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in item.approved_bys" :key="i">
                    <v-list-item-content>
                      <v-list-item-title>
                        <div v-if="item.status === 'pending'">
                          <span class="status pending">pending</span>
                        </div>
                        <div v-else-if="item.status === 'approved'">
                          <span class="status approve">approve</span>
                        </div>
                        <div v-else-if="item.status === 'rejected'">
                          <span class="status rejected">rejected</span>
                        </div>
                        <div v-else>
                          <span class="status pending">pending</span>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </td>
            <!-- <td>
              <div v-if="item.status === 'pending'">
                <span class="status pending">pending</span>
              </div>
              <div v-if="item.status === 'approved'">
                <span class="status approve">approve</span>
              </div>
              <div v-if="item.status === 'rejected'">
                <span class="status rejected">rejected</span>
              </div>
            </td> -->
          </tr>
        </tbody>
      </table>
    </v-sheet>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import Loading from "@/components/Loading";

export default {
  name: "TodoList",
  data() {
    return {
      listDepartment: [],
      department_id: "",
      listBranch: [],
      branch_id: "",
      isLoading: false,
      status: "all",
      filter: "",
      todoStatus: [
        {
          id: 0,
          name: "all",
        },
        {
          id: 1,
          name: "pending",
        },
        {
          id: 2,
          name: "approved",
        },
        {
          id: 3,
          name: "rejected",
        },
      ],
      startDate: false,
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      endDate: false,
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      approved: [],
    };
  },
  components: {
    Loading,
  },
  methods: {
    openFile(url) {
      window.open(url, "_blank", "noreferrer");
    },
    fetchEApproval() {
      this.isLoading = true;
      this.$axios
        .post(`company/report-approvals`, {
          star_date: moment(this.start_date).format("YYYY-MM-DD"),
          end_date: moment(this.end_date).format("YYYY-MM-DD"),
          status: this.status,
          filter: this.filter,
          department_id: this.department_id,
          branch_id: this.branch_id
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            this.approved = res.data.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    exportExcel() {
      this.isLoading = true;
      const item = {
        star_date: moment(this.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
        status: this.status,
      };
      this.$axios
        .post(`company/export-approvals-to-excel`, item, {
          responseType: "blob",
        })
        .then((res) => {
          this.isLoading = false;
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "e-approval.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    handleDepartment(department_id) {
      this.department_id = department_id;
      this.fetchEApproval(this.department_id);
      this.pagination.current_page = 1;
    },

    handleBranch(branch_id) {
      this.branch_id = branch_id;
      this.fetchEApproval(this.branch_id);
      this.pagination.current_page = 1;
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");
    this.fetchEApproval();
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.main-report {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(223, 230, 233, 0.3);
  padding: 8px;
  font-family: $font-family;

  h2 {
    font-size: 20px;
  }

  .card-select-date {
    display: flex;
    align-items: center;
    padding: 8px;
  }
}

.status {
  font-size: 12px;
  letter-spacing: 1px;
  padding: 2px 8px;
  width: 60px;
  height: 20px;
  text-transform: capitalize;
  font-family: $font-family;
  border-radius: 60px;
}

.pending {
  background-color: rgba(128, 142, 155, 1);
  color: #ffffff;
}

.confirm {
  background-color: rgba(46, 204, 113, 1) !important;
  color: #ffffff;
}

.updated {
  background-color: rgba(255, 221, 89, 1) !important;
  color: #ffffff;
}

.rejected {
  background-color: rgba(192, 57, 43, 1) !important;
  color: #ffffff;
}

.approve {
  background-color: rgba(38, 222, 129, 1);
  color: #ffffff;
}

.success {
  background-color: rgba(30, 144, 255, 1) !important;
  color: #ffffff;
}
</style>
